import React from "react";
import { Radar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
  ChartOptions,
  Plugin,
} from "chart.js";

// RadialLinearScale 인터페이스 확장
interface ExtendedRadialLinearScale extends RadialLinearScale {
  xCenter: number;
  yCenter: number;
  drawingArea: number;
}

// Chart.js 모듈 등록
ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend
);

// 배경 색상을 추가하는 커스텀 플러그인 타입 정의
const circularBackgroundPlugin: Plugin<"radar"> = {
  id: "circularBackgroundPlugin",
  beforeDraw: (chart) => {
    const { ctx, scales } = chart;
    const radialScale = scales.r as ExtendedRadialLinearScale; // 확장된 RadialLinearScale 사용
    const centerX = radialScale.xCenter;
    const centerY = radialScale.yCenter;
    const radiusMax = radialScale.drawingArea;

    const ranges = [
      { max: 100, color: "rgba(102, 205, 170, 1)" },
      { max: 80, color: "rgba(135, 206, 235, 1)" },
      { max: 60, color: "rgba(255, 215, 0, 1)" },
      { max: 40, color: "rgba(255, 170, 0, 1)" },
      { max: 20, color: "rgba(255, 77, 77, 1)" },
    ];

    const radiusStep = radiusMax / ranges.length;
    ranges.forEach((range, index) => {
      const currentRadius = radiusMax - radiusStep * index;
      ctx.beginPath();
      ctx.arc(centerX, centerY, currentRadius, 0, 2 * Math.PI);
      ctx.fillStyle = range.color;
      ctx.fill();
      ctx.closePath();
    });
  },
};

const RadarChart: React.FC = () => {
  const data = {
    labels: ["인지도", "정보습득", "거부능력"],
    datasets: [
      {
        label: "위험도",
        data: [54.44, 46.64, 46.43],
        backgroundColor: "rgba(0, 123, 255, 0.2)",
        borderColor: "#333",
        pointBackgroundColor: "#ffffff",
        borderWidth: 2,
        pointRadius: 6,
      },
    ],
  };

  const options: ChartOptions<"radar"> = {
    scales: {
      r: {
        grid: {
          circular: true,
        },
        angleLines: {
          display: true,
          color: "#ffffff",
          lineWidth: 2,
        },
        suggestedMin: 0,
        suggestedMax: 100,
        ticks: {
          stepSize: 20,
          backdropColor: "transparent",
          color: "#333",
          font: {
            size: 14,
            family: "Pretendard", // Title font 설정
          },
        },
        pointLabels: {
          font: {
            size: 14,
            family: "Pretendard", // Title font 설정
          },
          color: "#333",
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        display: false,
      },
    },
  };

  return (
    <div>
      <Radar
        data={data}
        options={options}
        plugins={[circularBackgroundPlugin]}
      />
    </div>
  );
};

export default RadarChart;
