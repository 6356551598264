// ReportGradePage.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import dividerStyles from "../../styles/coverPage/CoverPageDivider.module.css";
import styles from "../../styles/reportPage/ReportPageBody.module.css";
import footer from "../../styles/common/CommonFooter.module.css";
import pageStyles from "../../styles/common/CommonPageLayout.module.css";
import ReportSection from "../../components/report/ReportSection";
import HorizontalGradeBarChart from "../../components/charts/HorizontalGradeBarChart";
import BasicGradeBarChart from "../../components/charts/BasicGradeBarChart";
import DoughnutGradeBarChart from "../../components/charts/DoughnutGradeBarChart";
import reportButtonStyles from "../../styles/reportPage/ReportPageButton.module.css";
import buttonStyles from "../../styles/common/CommonButton.module.css";

const ReportGradePage: React.FC = () => {
  const navigate = useNavigate();

  const handleReportPageClick = () => {
    navigate("/reportPage");
  };

  const handleCoverPageClick = () => {
    navigate("/");
  };

  const handleHonorPageClick = () => {
    navigate("/honorPage");
  };

  return (
    <div className={pageStyles.coverPage}>
      <header>
        <div className={dividerStyles.divider}>
          <div className={dividerStyles.sectionOrange}></div>
          <div className={dividerStyles.sectionGreen}></div>
          <div className={dividerStyles.sectionBlue}></div>
        </div>
      </header>

      <main className={styles.reportBody}>
        <div className={styles.sectionAndChartsContainer}>
          <ReportSection
            title="학년 - 종합 결과"
            subtitle="교영 초등학교의 마약 인식도 검사 결과입니다."
          />

          <div className={styles.flexContainer}>
            {/* 영역별 결과 - 영역별 인식도 */}
            <div className={styles.tableAndChartContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.reportTable}>
                  <thead>
                    <tr>
                      <th className={styles.grayBackground} rowSpan={2}>
                        학년
                      </th>
                      <th className={styles.grayBackground} rowSpan={2}>
                        인원
                      </th>
                      <th className={styles.grayBackground} colSpan={2}>
                        종합 인식도
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.grayBackground}>지수</th>
                      <th className={styles.grayBackground}>평균(%)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>1학년</td>
                      <td>188명</td>
                      <td>주의</td>
                      <td>54.01%</td>
                    </tr>
                    <tr>
                      <td>2학년</td>
                      <td>186명</td>
                      <td>주의</td>
                      <td>52.46%</td>
                    </tr>
                    <tr>
                      <td>3학년</td>
                      <td>152명</td>
                      <td>주의</td>
                      <td>50.45%</td>
                    </tr>
                    <tr>
                      <td>4학년</td>
                      <td>179명</td>
                      <td>주의</td>
                      <td>49.14%</td>
                    </tr>
                    <tr>
                      <td>5학년</td>
                      <td>194명</td>
                      <td>주의</td>
                      <td>49.47%</td>
                    </tr>
                    <tr>
                      <td>6학년</td>
                      <td>198명</td>
                      <td>주의</td>
                      <td>42.37%</td>
                    </tr>
                    <tr>
                      <td colSpan={4}>
                        <div
                          className={styles.chartContainer}
                          style={{ height: "500px" }}
                        >
                          <BasicGradeBarChart />
                        </div>
                        <div
                          className={styles.chartContainer}
                          style={{ height: "500px" }}
                        >
                          <DoughnutGradeBarChart />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            {/* 영역별 결과 - 학생 분포도 */}
            <div className={styles.tableAndChartContainer}>
              <div className={styles.tableContainer}>
                <table className={styles.reportTable}>
                  <thead>
                    <tr>
                      <th className={styles.grayBackground} colSpan={5}>
                        학생 분포도
                      </th>
                    </tr>
                    <tr>
                      <th className={styles.grayBackground}>위험</th>
                      <th className={styles.grayBackground}>경계</th>
                      <th className={styles.grayBackground}>주의</th>
                      <th className={styles.grayBackground}>관심</th>
                      <th className={styles.grayBackground}>안전</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>0명</td>
                      <td>17명</td>
                      <td>142명</td>
                      <td>19명</td>
                      <td>10명</td>
                    </tr>
                    <tr>
                      <td>0명</td>
                      <td>14명</td>
                      <td>148명</td>
                      <td>16명</td>
                      <td>8명</td>
                    </tr>
                    <tr>
                      <td>0명</td>
                      <td>23명</td>
                      <td>114명</td>
                      <td>7명</td>
                      <td>8명</td>
                    </tr>
                    <tr>
                      <td>0명</td>
                      <td>27명</td>
                      <td>142명</td>
                      <td>5명</td>
                      <td>5명</td>
                    </tr>
                    <tr>
                      <td>0명</td>
                      <td>21명</td>
                      <td>141명</td>
                      <td>10명</td>
                      <td>13명</td>
                    </tr>
                    <tr>
                      <td>39명</td>
                      <td>24명</td>
                      <td>117명</td>
                      <td>18명</td>
                      <td>0명</td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <div
                          className={styles.chartContainer}
                          style={{ height: "1000px" }}
                        >
                          <HorizontalGradeBarChart />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className={reportButtonStyles.buttonContainer}>
          <button
            className={buttonStyles.startButton}
            onClick={handleReportPageClick}
          >
            종합 결과 보기
          </button>
          <button
            className={buttonStyles.startButton}
            onClick={handleCoverPageClick}
          >
            처음으로
          </button>
          {/* <button onClick={handleHonorPageClick}>테스트</button> */}
        </div>
      </main>

      <footer className={footer.footer}>
        <div className={footer.footerText}>
          <p className={footer.organizationText}>
            청소년
            <span className={footer.orangeText}>건강</span>ㆍ
            <span className={footer.blueText}>활동</span>
            진흥재단 / 공공교육청
          </p>
          <p>
            Copyright © 2008. Youth Health&Work Foundation All Right Reserved
          </p>
          <p>전화번호 : 02-747-1388</p>
        </div>
      </footer>
    </div>
  );
};

export default ReportGradePage;
