// CoverPage.tsx
import React from "react";
import { useNavigate } from "react-router-dom";
import pageStyles from "../../styles/common/CommonPageLayout.module.css";
import coverHeaderStyles from "../../styles/coverPage/CoverPageHeader.module.css";
import coverbodyStyles from "../../styles/coverPage/CoverPageBody.module.css";
import typographyStyles from "../../styles/coverPage/CoverPageTypography.module.css";
import dividerStyles from "../../styles/coverPage/CoverPageDivider.module.css";
import tableStyles from "../../styles/coverPage/CoverPageTable.module.css";
import imageStyles from "../../styles/coverPage/CoverPageImage.module.css";
import coverButtonStyles from "../../styles/coverPage/CoverPageButton.module.css";
import footer from "../../styles/common/CommonFooter.module.css";
import buttonStyles from "../../styles/common/CommonButton.module.css";
import youthLogo from "../../assets/images/youth_logo.png";
import dragImage from "../../assets/images/main_drag.webp";

const CoverPage: React.FC = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/examPage");
  };

  return (
    <div className={pageStyles.coverPage}>
      <header className={`${coverHeaderStyles.header} ${pageStyles.header}`}>
        <img
          src={youthLogo}
          alt="youth Logo"
          className={coverHeaderStyles.logoLeft}
        />
        <div className={coverHeaderStyles.schoolBox}>학교용</div>
      </header>

      <main className={coverbodyStyles.body}>
        <div className={typographyStyles.bodyText}>
          <p className={typographyStyles.bodyTextP}>
            마약 ㅣ 온라인도박 ㅣ 디지털범죄
          </p>
          <h1 className={typographyStyles.bodyTextH1}>마약 인식도 검사</h1>
        </div>

        <div className={dividerStyles.divider}>
          <div className={dividerStyles.sectionOrange}></div>
          <div className={dividerStyles.sectionGreen}></div>
          <div className={dividerStyles.sectionBlue}></div>
        </div>

        <div className={imageStyles.imageContainer}>
          <img
            src={dragImage}
            alt="Disaster"
            className={imageStyles.disasterImage}
            loading="lazy"
          />
        </div>

        <p className={typographyStyles.greetingText}>
          ※ 본 검사는 마약 관련 검사지 입니다.
        </p>

        <section className={tableStyles.infoSection}>
          <table className={tableStyles.infoTable}>
            <tbody>
              <tr>
                <th
                  className={`${tableStyles.infoTableTh} ${tableStyles.firstColumn}`}
                >
                  학교
                </th>
                <td className={tableStyles.infoTableTd}>교외 초등학교</td>
              </tr>
              <tr>
                <th
                  className={`${tableStyles.infoTableTh} ${tableStyles.firstColumn}`}
                >
                  검사일
                </th>
                <td className={tableStyles.infoTableTd}>2016년 03월 03일</td>
              </tr>
              <tr>
                <th
                  className={`${tableStyles.infoTableTh} ${tableStyles.firstColumn}`}
                >
                  검사인원
                </th>
                <td
                  className={`${tableStyles.infoTableTd} ${tableStyles.lastColumn}`}
                >
                  총 1,097명
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <div className={coverButtonStyles.buttonContainer}>
          <button className={buttonStyles.startButton} onClick={handleClick}>
            시작하기
          </button>
        </div>
      </main>

      <footer className={footer.footer}>
        <div className={footer.footerText}>
          <p className={footer.organizationText}>
            청소년
            <span className={footer.orangeText}>건강</span>ㆍ
            <span className={footer.blueText}>활동</span>
            진흥재단 / 공공교육청
          </p>
          <p>
            Copyright © 2008. Youth Health&Work Foundation All Right Reserved
          </p>
          <p>전화번호 : 02-747-1388</p>
        </div>
      </footer>
    </div>
  );
};

export default CoverPage;
