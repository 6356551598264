import React, { useState, useRef, MutableRefObject } from "react";
import { useNavigate } from "react-router-dom";
import questionsData from "../../assets/data/earthquake_quiz.json";
import pageStyles from "../../styles/common/CommonPageLayout.module.css";
import footer from "../../styles/common/CommonFooter.module.css";
import buttonStyles from "../../styles/common/CommonButton.module.css";
import dividerStyles from "../../styles/coverPage/CoverPageDivider.module.css";
import examButtonStyles from "../../styles/examPage/ExamPageButton.module.css";
import styles from "../../styles/examPage/ExamPageBody.module.css";
import questionStyles from "../../styles/examPage/ExamPageQuestion.module.css";

interface QuestionData {
  question: string;
  options: string[];
}

interface QuestionsData {
  questions: QuestionData[];
}

const ExamPage: React.FC = () => {
  const navigate = useNavigate();
  const [selectedOptions, setSelectedOptions] = useState<Array<number | null>>(
    Array(questionsData.questions.length).fill(null)
  );

  // Refs for each question and the submit button
  const questionRefs = useRef<Array<HTMLDivElement | null>>([]);
  const submitButtonRef = useRef<HTMLDivElement | null>(null);

  const handleOptionSelect = (questionIndex: number, optionIndex: number) => {
    const updatedSelections = [...selectedOptions];
    updatedSelections[questionIndex] = optionIndex;
    setSelectedOptions(updatedSelections);

    // Scroll to the next question or submit button
    if (questionIndex < questionsData.questions.length - 1) {
      questionRefs.current[questionIndex + 1]?.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    } else {
      submitButtonRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  };

  const allQuestionsAnswered = selectedOptions.every(
    (option) => option !== null
  );

  const handleClick = () => {
    if (allQuestionsAnswered) {
      const confirmSubmit = window.confirm("제출하시겠습니까?");
      if (confirmSubmit) {
        navigate("/reportPage");
      }
    } else {
      alert("모든 문항에 답변해 주세요.");
    }
  };

  return (
    <div className={pageStyles.coverPage}>
      <header>
        <div className={dividerStyles.divider}>
          <div className={dividerStyles.sectionOrange}></div>
          <div className={dividerStyles.sectionGreen}></div>
          <div className={dividerStyles.sectionBlue}></div>
        </div>
      </header>

      <main className={styles.examBody}>
        {questionsData.questions.map(
          (questionObj: QuestionData, questionIndex: number) => (
            <div
              key={questionIndex}
              className={questionStyles.questionBlock}
              ref={(el) => {
                questionRefs.current[questionIndex] = el;
              }}
            >
              <h2 className={questionStyles.questionBlockH2}>{`${
                questionIndex + 1
              }. ${questionObj.question}`}</h2>
              <ul className={questionStyles.questionBlockUl}>
                {questionObj.options.map((option, optionIndex) => (
                  <li
                    key={optionIndex}
                    className={questionStyles.questionBlockLi}
                  >
                    <label className={questionStyles.questionBlockLabel}>
                      <input
                        type="radio"
                        name={`question-${questionIndex}`}
                        checked={selectedOptions[questionIndex] === optionIndex}
                        onChange={() =>
                          handleOptionSelect(questionIndex, optionIndex)
                        }
                        className={questionStyles.questionBlockInput}
                      />
                      {option}
                    </label>
                  </li>
                ))}
              </ul>
            </div>
          )
        )}

        <div className={examButtonStyles.buttonContainer} ref={submitButtonRef}>
          <button className={buttonStyles.startButton} onClick={handleClick}>
            제출하기
          </button>
        </div>
      </main>

      <footer className={footer.footer}>
        <div className={footer.footerText}>
          <p className={footer.organizationText}>
            청소년
            <span className={footer.orangeText}>건강</span>ㆍ
            <span className={footer.blueText}>활동</span>
            진흥재단 / 공공교육청
          </p>
          <p>
            Copyright © 2008. Youth Health&Work Foundation All Right Reserved
          </p>
          <p>전화번호 : 02-747-1388</p>
        </div>
      </footer>
    </div>
  );
};

export default ExamPage;
